import React from 'react';
import './App.css';
import Navigation from './navigation.js';
import Footerband from './components/footerband';
//import { loadReCaptcha } from 'react-recaptcha-v3';
import "core-js/stable";
import "regenerator-runtime/runtime";

const App = () => {
    return (
      <>
        <Navigation />
        <Footerband />
        </>
    );
  }


export default App;
