import React from 'react';
import Axios from 'axios';
import { Carousel, Spinner, Row, Col, Container } from 'react-bootstrap'
import shortid from 'shortid';
import LazyLoad from 'react-lazyload';

class Imagesnotloaded extends React.Component {
  render() {
    return (
      <>
        <Container>
          <Row>
            <Col fluid="true" style={{ textAlign: 'center', height: '90%', 'margin': '20% 0 20% 0', 'display': 'block' }}>
              <Spinner animation="border" />
              <span style={{ opacity: '60%' }}><br /><br />Loading...</span>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

class CarouselItemList extends React.Component {
  constructor(e) {
    super(e);
    this.state = { isLoaded: false };
  }

  componentDidMount() {
    this.setState({ isLoaded: true });
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <>
          <LazyLoad once height={1600}>
            <Carousel controls={false} className="topCarousel" fade={true}>

              {this.props.imagelist.map(item =>

                <Carousel.Item key={shortid.generate()} >
                  <img
                    className="d-block w-100"
                    src={item.image.url}
                    alt={item.alttext}
                  />

                  <Carousel.Caption>
                    <h3>{item.caption}</h3>
                  </Carousel.Caption>

                </Carousel.Item>
              )}
            </Carousel>
          </LazyLoad>
        </>
      )

    } else return (

      <Imagesnotloaded />
    )
  }
}

class MyCarousel extends React.Component {

  constructor(e) {
    super(e);
    this.state = { isLoaded: false };
  }

  componentDidMount() {
    Axios.get('data/slider.json')
      .then(res => {
        this.setState({ imagelist: res.data, isLoaded: true });
      });
  }

  render() {

    if (this.state.isLoaded) {
      return (
        <>
          <CarouselItemList imagelist={this.state.imagelist} />
        </>
      )
    } else return (
      <Imagesnotloaded />
    )
  }
}

export default MyCarousel;
