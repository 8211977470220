import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import CrapSpreader1 from '../images/crap_spreader1lores.jpg';
import CrapSpreader2 from '../images/crap_spreader2lores.jpg';
import CrapSpreader3 from '../images/crap_spreader3lores.jpg';

const MachineControl = () => {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SOFTAC | Engenious Machine Control</title>
                    <link rel="canonical" href="https://www.softac.com/machine-control" />
                    <meta name="description" content="Programming logic and custom remote machine controls including the internet of things." />
                </Helmet>

                <br />
                <Container>
                    <h1 className="header-highlight">Engenious Machine Control</h1>
                    <Row className="justify-content-md-center text-left">
                        <Col md="12">
                            <h2>Summary</h2>    <br />
                            <img src={CrapSpreader1} alt="Agricultural Spreader" style={{ float: "right", width: "38%", "padding-left": "3%" }} />
                            Over the past number of years Softac has developed and has been refining a series of remote control systems that
                            can be utilized in a host of different applications.  Our “sweet spot” is in applications that require remote monitoring
                            and remote control of engines and hydraulics.  Utilizing cellular communications, you can access your equipment from any
                            web enabled device located anywhere in the world.   Softac utilizes ultra low bandwidth communication methodologies,
                            keeping your monthly data subscription to a minimum, and utilizes state of the art technology to offer you a graphically
                            rich and extremely intuitive user interface where you can monitor and control all aspects of your equipment. <br /><br />


                            <ListGroup>

                                <div><h4>High level specifications:</h4></div>
                                <ListGroup.Item className="cardstyles" style={{ marginTop: '10px', width: "60" }}>12vdc and 24vdc applications. We can also incorporate 120VAC for stationary applications</ListGroup.Item>
                                <ListGroup.Item className="cardstyles" style={{ marginTop: '10px' }}>Cellular - 3G, 4G, 5G and LTE </ListGroup.Item>
                                <ListGroup.Item className="cardstyles" style={{ marginTop: '10px' }}>NEMA 4X Polycarbonate enclosure</ListGroup.Item>
                                <ListGroup.Item className="cardstyles" style={{ marginTop: '10px' }}>Vibration isolators</ListGroup.Item>
                                <ListGroup.Item className="cardstyles" style={{ marginTop: '10px' }}>CANbus J1939, CANopen, Ethernet IP,and MODbus</ListGroup.Item>
                                <ListGroup.Item className="cardstyles" style={{ marginTop: '10px' }}>On board I/O for monitoring Fuel level, pressures, temperatures and control of valves, lights, etc.</ListGroup.Item>
                                <ListGroup.Item className="cardstyles" style={{ marginTop: '10px' }}>Ability to connect more than one system together to work seamlessly over cellular networks</ListGroup.Item>
                                <ListGroup.Item className="cardstyles" style={{ marginTop: '10px' }}>Complete elimination of traditional Murphy panels, further reducing your cost & complexity</ListGroup.Item>
                            </ListGroup>

                            <br />

                            <ListGroup>

                                <h4>Applications/use cases:</h4><br />
                                <ListGroup.Item className="cardstyles" style={{ marginTop: '10px' }}>Water, effluent and agricultural pumping applications<br /></ListGroup.Item>
                                <ListGroup.Item className="cardstyles" style={{ marginTop: '10px' }}>Any industrial equipment requiring remote access<br /></ListGroup.Item>
                            </ListGroup>
                            <br />
                            <div style={{ "text-align": "center" }}>
                                <img src={CrapSpreader3} alt="Agricultural Spreader" style={{ "text-align": "center", width: "50%", "padding": "3%" }} />
                                <img src={CrapSpreader2} alt="Agricultural Spreader" style={{ float: "center", width: "50%", "padding": "3%" }} />
                            </div>
                        </Col></Row>
                </Container>
                <br />

            </>

        )
}

export default MachineControl;
