import { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Contactus from './pages/contact_us';
import Index from './pages/index';
import Capabilities from './pages/capabilities';
import DoWeFit from './pages/dowefit';
import NoMatch from './pages/nomatch'; // 404 page
import DigiStart from './pages/digistart';
import DigiBrake from './pages/digibrake';
import MachineControl from './pages/machine-control'
//import sunWalker from './pages/sunwalker';
import ReactGA from 'react-ga';
import { React } from 'react';

const Navigation = () => {
  const [isLoaded, setIsLoaded] = useState(false); 
  
  useEffect(()=> {
    if (typeof window !== 'undefined' && window.location) {
      ReactGA.pageview(window.location.pathname);
    } else {
      console.warn('Unable to track pageview: window or location is undefined');
    }

    if(!isLoaded) 
      setIsLoaded(true);
  }, [isLoaded]);

    if(!isLoaded)
      return (
        <div className="loading">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
    )

    return (
      <>
        <Router>
          <Navbar bg="dark" variant="dark" expand="lg" className="headernavbar sticky-top">
            <Navbar.Brand href="/"><span className="topleftlogo"></span></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Nav.Item>
                <Nav.Link className="homeLink nav-link" href="/">HOME</Nav.Link>
                </Nav.Item>
                <NavDropdown title="PRODUCTS AND CAPABILITIES" id="basic-nav-dropdown">
                  <NavDropdown.Item className="nav-link" href="/capabilities">What we are capable of</NavDropdown.Item>
                  <NavDropdown.Item className="nav-link" href="/digi-brake">DC Injection Brakes</NavDropdown.Item>
                  <NavDropdown.Item className="nav-link" href="/digi-start">Electronic Soft Starters</NavDropdown.Item>
                  <NavDropdown.Item className="nav-link" href="/machine-control">Engenious Machine Control</NavDropdown.Item>
 { /*               <NavDropdown.Item className="nav-link" href="/sunwalker">Sunwalker Crosswalk System</NavDropdown.Item> */ }

                  {/*   <NavDropdown.Item className="nav-link" href="/crosswalk-flasher">Sunwalker Crosswalk Flasher</NavDropdown.Item>                
               */}
                </NavDropdown>
                <Nav.Item>
                <Nav.Link className="nav-link" href="/why-us">WHY US</Nav.Link>
                </Nav.Item>
                <Nav.Item>

                <Nav.Link className="nav-link contactUs" href="/contact-us/" ><div>CONTACT US</div></Nav.Link>
                </Nav.Item>
              </Nav>

            </Navbar.Collapse>
          </Navbar>

          <Switch>
            <Route path="/" exact component={Index} />
            <Route path="/capabilities" component={Capabilities} />
            <Route path="/contact-us" component={Contactus} />
            <Route path="/why-us" component={DoWeFit} />
            <Route path="/digi-start" component={DigiStart} />
            <Route path="/digi-brake" component={DigiBrake} />
            <Route path="/machine-control" component={MachineControl} />
           {/* <Route path="/sunwalker" component={sunWalker} /> */}
            <Route path="/404.html" component={NoMatch} />
            <Redirect to="/404.html" />
          </Switch>
        </Router>

      </>
    );
  }

export default Navigation;
