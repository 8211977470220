import React from 'react';
import { Col } from 'react-bootstrap';

const fiftyYears = () => {
    return ( 
        <Col md="12" >
        <article>
          <div  style={{background: "rgba(10,90,180,.8)", 'border-radius': '5px', 'padding-bottom': '20px'}}>            
        <h2><br/>50 YEARS OF SOFTAC</h2>
        <br/>
            After <strong>50 years</strong> of service, SOFTAC has
            honed its skills in many industries and has
            delivered automated solutions to
            thousands of businesses across the world.
            Centrally located in the Fraser Valley of British
            Columbia, our team has developed key
            partnerships with global leaders - both
            customers and vendors.
            Our CSA/UL certified control panel shop employs
            unprecedented manufacturing methodologies
            & processes designed to build in repeatability,
            quality and eliminate waste so you don’t pay
            more than is necessary without compromising
            value and performance.
            
        </div>
        </article>
        </Col>
    );
  }
export default fiftyYears;
