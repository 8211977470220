import React from 'react';
import { Navbar, Nav, Col, Row, Container } from 'react-bootstrap';
import Axios from 'axios';
import TakeControl from "../images/50yearstc.jpg";

export default class Footerband extends React.Component {
  getYear() {
    var d = new Date();
    return d.getFullYear();
  }

  constructor(e) {
    super(e);
    this.state = { isLoaded: false }
  }

  componentDidMount() {
    Axios.get('https://www.softac.com/data/footerlogo.json').then(res => {
      this.setState({ isLoaded: true, logo: res.data[0] });
      this.setState({ isLoaded: true, logo: res.data[0] });
    });
  }

  render() {
    const logo = { ...this.state.logo };

    if (this.state.isLoaded) {

      return (
        <>

          <div className="justify-content-md-center text-left footer_center footband_holder">
            <Container><Row>
              <Col sm>
                <h4>Contact Us</h4>
                Unit 101 - 8071 Aitken Road<br />
                Chilliwack, BC<br />
                V2R 4H5<br />
                Canada<br />
                <a href="tel:1-604-888-9507">604-888-9507</a><br />
                <a href="mailto:sales@softac.com">SALES@SOFTAC.COM</a><br />
              </Col>

                <Col sm className="fifty_year_holder" >
                  <img className="fiftyYears" src={TakeControl} alt={logo.alttext}></img><br />
                </Col>
    

              <Col sm >

                <div className="floatright">
                  <h4>Site Map</h4>
                  <a href="/">HOME</a> <br />
                  <a href="/capabilities">CAPABILITIES</a> <br />
                  <a href="/digi-start">DIGI-START</a><br />
                  <a href="/digi-brake">DIGI-BRAKE</a><br />
                  <a href="/why-us">WHY US</a> <br />
                  <a href="/contact-us" >CONTACT US</a> <br />
                </div>
              </Col>
            </Row></Container>
          </div>

          <Navbar bg="light" expand="lg" className="footer_row justify-content-center">
            <Nav>
              <Nav.Item>Site Copyright <this.getYear /> by Softac Systems Ltd. &nbsp; Your source for Electrical Control Systems and Automation.</Nav.Item>
            </Nav>
          </Navbar>
        </>
      )
    } else return null;
  }
}
