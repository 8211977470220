import React from "react";
import { Form, Button, FormControl } from 'react-bootstrap';
import axios from "axios";
import { withRouter } from 'react-router-dom';

class ContactForm extends React.Component {
  constructor(p) {
    super(p);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      postdata: {
        title: "Contact Us Form",
        name: '',
        company: '',
        email: '',
        phone: '',
        comments: ''
      },
      production: false,
      submitted: false
    };
  }

  handleChange = (event) => {
    const { postdata } = this.state;
    const target = event.target.name;
    postdata[target] = event.target.value;
    this.setState({ postdata: postdata });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    var fail = false;
    Object.keys(this.state.postdata).forEach((title, i) => {
      if (this.state.postdata[title] === '') {
        fail = true;
      }
    });

    if (fail)
      alert("Please fill out all of the fields.");

    else {  // call emailer through express
        //console.log(postdata);
        //axios.post('https://www.softac.com/sendcontactemail', this.state.postdata)
        axios.post('https://puivspo1u7.execute-api.us-west-2.amazonaws.com/contactform/webemailer', 
          JSON.stringify(this.state.postdata), 
          { headers: { 'content-type': 'application/json;charset=utf-8'} }).then((r) => {
          this.setState({ submitted: true });
        }).catch((error) => {
          console.log(error);
        }
        )
    }
  }

  render() {
    if (!this.state.submitted) {
      return (
        <>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlid="formBasicEmail">
              <Form.Label>Name</Form.Label>

              <Form.Control type="text" name="name" onBlur={this.handleChange} placeholder="Your Name" controlid={0} />

              <Form.Label>Company</Form.Label>
              <Form.Control type="text" name="company" onBlur={this.handleChange} placeholder="Your Company" controlid={1} />

              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" onBlur={this.handleChange} placeholder="Enter email" controlid={2} />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>

              <Form.Label>Contact phone number</Form.Label>

              <Form.Control type="telephone" name="phone" onBlur={this.handleChange} placeholder="Your telephone" controlid={3} />

              <Form.Label>Your message to us</Form.Label>

              <FormControl as="textarea" rows="8" name="comments" onBlur={this.handleChange} placeholder="Enter your notes or comments here." controlid={4} />

            </Form.Group>

            <Button variant="primary" type="submit" >
              Contact Us
            </Button>

          </Form>
          <br />
        </>
      )
    } else {  // form has been submitted
      return (
        <>
          <div className="contactsuccessmsg">Thank you for contacting Softac Systems.</div>
        </>
      );
    }
  }
}

export default withRouter(ContactForm);
